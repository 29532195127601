import React, { useEffect, useReducer, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Button from 'react-bootstrap/Button';
import Product from '../components/Product';
import LinkContainer from 'react-router-bootstrap/LinkContainer';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import './searchScreen.css';

const fetchFilterConfigurations = async () => {
  try {
    const response = await axios.get(`/api/products/filterParameters`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch filter configurations:', error);
    throw error; // Rethrow to handle it in the calling context
  }
};

const fetchSortParameters = async () => {
  try {
    const response = await axios.get(`/api/products/sortParameters`);
    return response.data; // Assuming this returns an array of sort options
  } catch (error) {
    console.error('Failed to fetch sort parameters:', error);
    throw error;
  }
};

const initialState = {
  products: [],
  page: 0,
  pages: 0,
  countProducts: 0,
  filterRenderItems: {},
  loading: true,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...initialState, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
        countProducts: action.payload.countProducts,
        filterRenderItems: action.payload.filterRenderItems,
        loading: false,
        error: null,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default function SearchScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const sp = new URLSearchParams(location.search);
  const query = sp.get('query') || 'all';
  // const order = sp.get('order') || 'newest';
  const page = sp.get('page') || 1;
  const [order, setOrder] = useState(sp.get('order') || 'findfast_rank'); // useState for managing order state
  const [category, setCategory] = useState(sp.get('categories_flat') || ''); // useState for category in url

  // Effect to sync URL changes with local state
  useEffect(() => {
    const newOrder = sp.get('order') || 'findfast_rank';

    if (newOrder !== order) {
      setOrder(newOrder);
    }
  }, [location.search]); // Dependency on location.search ensures this runs when URL changes
  useEffect(() => {
    // Ensure this runs only if there's a valid order to navigate with
    if (order) {
      navigate(getFilterUrl({ order, page: 1 }));
    }
  }, [order]); // Dependency on 'order' ensures it runs when 'order' changes

  // Handler for changing sort order
  const handleSortChange = (newOrder) => {
    setOrder(newOrder); // Only update state here
  };

  const [
    { loading, error, products, pages, countProducts, filterRenderItems },
    dispatch,
  ] = useReducer(reducer, initialState);

  const [sortParameters, setSortParameters] = useState([]);
  useEffect(() => {
    const loadSortParameters = async () => {
      try {
        const sortParams = await fetchSortParameters();
        setSortParameters(sortParams);
      } catch (error) {
        toast.error(getError(error));
      }
    };

    loadSortParameters();
  }, []); // Empty dependency array means this effect runs once on mount

  const [selectedFilters, setSelectedFilters] = useState({});

  const [filterConfigs, setFilterConfigs] = useState([]);

  const [innerSearchTerm, setInnerSearchTerm] = useState('');
  const [filtersLoading, setFiltersLoading] = useState(true);
  const [selectedGroupFilters, setSelectedGroupFilters] = useState({});

  useEffect(() => {
    const loadFilterConfigs = async () => {
      try {
        const configs = await fetchFilterConfigurations();
        setFilterConfigs(configs);
      } catch (error) {
        console.error('Failed to load filter configurations:', error);
      } finally {
        setFiltersLoading(false); // Ensure the loading state is set to false after fetching
      }
    };
    loadFilterConfigs();
  }, []);

  useEffect(() => {
    console.log(selectedFilters);
  }, [selectedFilters]);
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' }); // Dispatch loading action before fetching data
      try {
        if (category) {
          // Set categories_flat within selectedFilters and reset category
          selectedFilters.categories_flat = [category];
          setCategory('');
        }
        let queryString = Object.entries(selectedFilters)
          .reduce((acc, [key, values]) => {
            if (values.length > 0) {
              acc.push(`${key}=${values.join(',')}`);
            }
            return acc;
          }, [])
          .join('&');

        // Check if newSearch parameter is present in the URL and set it accordingly
        const newSearchParam = sp.get('newSearch')
          ? `&newSearch=${sp.get('newSearch')}`
          : '';

        if (queryString) {
          queryString = `&${queryString}`;
        }

        const { data } = await axios.get(
          `/api/products/search?page=${page}&query=${query}&order=${order}${queryString}${newSearchParam}`
        );
        // Process the filter items if they are of type 'hierarchy'
        if (data.filterRenderItems) {
          Object.keys(data.filterRenderItems).forEach((key) => {
            const currentFilterConfig = filterConfigs.find(
              (config) =>
                config.key === key && config.renderType === 'hierarchy'
            );
            if (currentFilterConfig) {
              const newSelectedItems = [...(selectedFilters[key] || [])];
              data.filterRenderItems[key].forEach((item) => {
                if (isParentSelected(item._id, selectedFilters[key] || [])) {
                  if (!newSelectedItems.includes(item._id)) {
                    newSelectedItems.push(item._id);
                  }
                  ensureParentsSelected(item._id, newSelectedItems);
                }
              });
              if (
                newSelectedItems.length > (selectedFilters[key] || []).length
              ) {
                setSelectedFilters((prevFilters) => ({
                  ...prevFilters,
                  [key]: newSelectedItems,
                }));
              }
            }
          });
        }
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [selectedFilters, order, page, query, location.search, filterConfigs]); // Update dependencies accordingly

  function ensureParentsSelected(item, selectedItems) {
    if (!item || !selectedItems) return false;
    let currentKey = item;
    while (currentKey) {
      const parentKey = getParentKey(currentKey);
      if (
        parentKey &&
        isParentSelected(parentKey, selectedItems) &&
        !selectedItems.includes(parentKey)
      ) {
        selectedItems.push(parentKey);
      }
      currentKey = parentKey;
    }
  }

  // Helper function to recursively check if any parent of the item is selected
  function isParentSelected(item, selectedItems) {
    if (!item || !selectedItems) return false;
    let parentKey = getParentKey(item);
    while (parentKey) {
      if (selectedItems.includes(parentKey)) {
        return true;
      }
      parentKey = getParentKey(parentKey);
    }
    return false;
  }

  // Function to derive parent key by removing last underscore and subsequent text
  function getParentKey(item) {
    if (!item || !item.includes('_')) return null;
    return item.substring(0, item.lastIndexOf('_'));
  }
  const handleFilterChange = (filterType, value, isChecked, clear) => {
    // If 'clear' is true, reset all filters and start fresh
    if (clear) {
      return { [filterType]: isChecked ? [value] : [] };
    }

    setSelectedFilters((prevFilters) => {
      // Copy the current state to avoid direct mutation
      const updatedFilters = { ...prevFilters };

      // Initialize the filter array if it doesn't exist
      if (!updatedFilters[filterType]) {
        updatedFilters[filterType] = [];
      }

      if (isChecked) {
        // Add the value to the filter array if checked
        updatedFilters[filterType] = [...updatedFilters[filterType], value];
      } else {
        // Remove the value from the filter array if unchecked
        updatedFilters[filterType] = updatedFilters[filterType].filter(
          (item) => item !== value
        );
      }

      return updatedFilters;
    });
  };
  const getFilterUrl = (filter) => {
    const filterPage = filter.page || page; // Default to page 1 if no page is specified
    let queryParams = new URLSearchParams({
      ...filter,
      page: filterPage, // Ensure page is included in the query parameters
      query: query,
      order: order,
    });

    Object.entries(selectedFilters).forEach(([key, values]) => {
      if (values.length) {
        queryParams.set(key, values.join(','));
      }
    });

    return `/search?${queryParams.toString()}`;
  };

  const RenderFilter = ({ filterConfig, filterData, groupedFilters }) => {
    const {
      type,
      key,
      dataEndpoint,
      data,
      displayName,
      renderType,
      valueKey,
      groupName,
    } = filterConfig;
    // Add useState call here for toggling display items in the checkbox list
    const [showAll, setShowAll] = useState(false); // State to toggle the visibility of all items

    if (valueKey == 'name') {
      filterData = filterData.map((item, index) => ({
        name: item._id,
        count: item.count,
      }));
    } else {
      filterData = data;
    }

    // Parse filterData into a hierarchical structure
    function parseHierarchy(filterData) {
      const hierarchy = {};
      filterData.forEach((item) => {
        const levels = item.split('_');
        let currentLevel = hierarchy;
        levels.forEach((level, index) => {
          if (!currentLevel[level]) {
            currentLevel[level] = index === levels.length - 1 ? {} : {}; // Initialize as object for next level
          }
          currentLevel = currentLevel[level];
        });
      });
      return hierarchy;
    }

    // Assumes setSelectedFilters is a state updater function for an object
    // where keys are filter categories and values are arrays of selected filter values.

    // Updates the state to reflect changes in hierarchical selections
    const handleHierarchyChange = (
      categoryKey,
      itemKey,
      isChecked,
      subItems
    ) => {
      setSelectedFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };

        // Ensure the category array exists
        if (!updatedFilters[categoryKey]) {
          updatedFilters[categoryKey] = [];
        }

        const toggleSelection = (key, isChecked) => {
          if (isChecked && !updatedFilters[categoryKey].includes(key)) {
            updatedFilters[categoryKey].push(key);
          } else if (!isChecked) {
            updatedFilters[categoryKey] = updatedFilters[categoryKey].filter(
              (item) => item !== key
            );
          }
        };

        // Recursively toggle sub-items
        const toggleSubItems = (key, isChecked, subItems) => {
          toggleSelection(key, isChecked);
          if (
            typeof subItems === 'object' &&
            Object.keys(subItems).length > 0
          ) {
            Object.keys(subItems).forEach((subKey) => {
              const fullKey = `${key}_${subKey}`;
              toggleSubItems(fullKey, isChecked, subItems[subKey]);
            });
          }
        };

        // Start the recursion with the initial item key
        toggleSubItems(itemKey, isChecked, subItems);
        // Ensure all parents are deselected if a child is deselected
        if (!isChecked) {
          const parts = itemKey.split('_');
          while (parts.length > 1) {
            parts.pop(); // Remove the last part to go up one level
            const parentKey = parts.join('_');
            const anySelected = updatedFilters[categoryKey].some((k) =>
              k.startsWith(parentKey + '_')
            );
            if (anySelected) {
              toggleSelection(parentKey, false);
            }
          }
        }

        return updatedFilters;
      });
    };

    const isSelected = (categoryKey, itemKey) => {
      const selected = selectedFilters[categoryKey] || [];

      // Check if the item is directly selected
      if (selected.includes(itemKey)) {
        return true;
      }
      return false;
    };

    switch (renderType) {
      case 'list':
        return (
          <div>
            <h3>{displayName}</h3>
            <ul>
              {filterData.map((item) => (
                <li
                  key={item[valueKey]}
                  style={{ cursor: 'pointer' }} // Add cursor style for better UX
                  className={
                    selectedFilters[key]?.includes(item[valueKey])
                      ? 'text-bold'
                      : ''
                  }
                  onClick={() => {
                    // Determine if the item is currently selected
                    const isSelected = selectedFilters[key]?.includes(
                      item[valueKey]
                    );
                    // Toggle the selection state
                    handleFilterChange(key, item[valueKey], !isSelected, false);
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        );

      case 'checkboxList':
        const visibleData = showAll ? filterData : filterData.slice(0, 10); // Determine visible items based on showAll state
        return (
          <div>
            {!groupName && <h3>{displayName}</h3>}
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              {visibleData.map((item) => (
                <li key={item[valueKey]}>
                  <label style={{ display: 'block', marginBottom: '8px' }}>
                    <input
                      type="checkbox"
                      value={item[valueKey]}
                      checked={
                        selectedFilters[key]?.includes(item[valueKey]) || false
                      }
                      onChange={(e) =>
                        handleFilterChange(
                          key,
                          item[valueKey],
                          e.target.checked,
                          false
                        )
                      }
                      style={{ marginRight: '8px' }}
                    />
                    <span style={{ maxWidth: '250px', wordWrap: 'break-word' }}>
                      {item.name}
                      {valueKey == 'name' ? ' (' + item.count + ')' : ''}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
            {filterData.length > 10 && (
              <button
                class="custom-button"
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? 'Show Less' : 'Show More'}
              </button>
            )}
          </div>
        );

      case 'hierarchy':
        // Parse the filterData into a hierarchical structure
        const hierarchyData = parseHierarchy(
          filterData.map((item) => item.name)
        );

        // Function to check if any item in the hierarchy is selected
        const isAnyItemSelected = (filterKey) => {
          return (selectedFilters[filterKey] || []).length > 0;
        };

        // Render the hierarchical structure with checkboxes for all items
        const renderHierarchy = (
          filterKey,
          hierarchyData,
          prefix = '',
          depth = 0
        ) => {
          // Check if any item is selected in the entire hierarchy
          const anyItemSelected = isAnyItemSelected(filterKey);

          // Check if it's the top level call and skip rendering the <ul> for it
          if (prefix === '') {
            return Object.values(hierarchyData).map((subHierarchy, index) =>
              renderHierarchy(
                filterKey,
                subHierarchy,
                Object.keys(hierarchyData)[index] + '_',
                depth + 1 // Increment depth as we go deeper
              )
            );
          } else {
            // Prevent rendering items more than 3 levels deep when no item is selected at all
            if (depth > 4 && !anyItemSelected) {
              return null;
            }

            return (
              <ul style={{ listStyleType: 'none', paddingLeft: '4px' }}>
                {Object.keys(hierarchyData).map((key) => (
                  <li key={key}>
                    <label>
                      <input
                        type="checkbox"
                        checked={isSelected(filterKey, prefix + key)}
                        onChange={(e) =>
                          handleHierarchyChange(
                            filterKey,
                            prefix + key,
                            e.target.checked,
                            hierarchyData[key]
                          )
                        }
                        style={{ marginRight: '8px' }}
                      />
                      <span
                        onClick={() => {
                          // Deactivate all other items and activate this one
                          setSelectedFilters({
                            ...selectedFilters,
                            [filterKey]: [prefix + key],
                          });
                        }}
                        style={{ cursor: 'pointer', userSelect: 'none' }}
                      >
                        {key}
                      </span>
                    </label>
                    {/* Recursive call for nested items */}
                    {typeof hierarchyData[key] === 'object' &&
                      renderHierarchy(
                        filterKey,
                        hierarchyData[key],
                        `${prefix + key}_`,
                        depth + 1 // Increment depth as we go deeper
                      )}
                  </li>
                ))}
              </ul>
            );
          }
        };

        return (
          <div>
            <h3>{displayName}</h3>
            {renderHierarchy(key, hierarchyData)}
          </div>
        );

      // Handle other renderTypes here if needed

      default:
        return null;
    }
  };

  function DisplaySelectedFilters({ selectedFilters }) {
    return (
      <div>
        {Object.entries(selectedFilters).map(([filterType, filterValues]) => {
          // Skip if filter is set to 'all' or if the array is empty
          if (
            filterValues === 'all' ||
            (Array.isArray(filterValues) && filterValues.length === 0)
          ) {
            return null;
          }

          // Convert filterValues to a readable string, handling both arrays and single values
          const valueString = Array.isArray(filterValues)
            ? filterValues.join(', ')
            : filterValues;

          // Generate a display name for the filter type (e.g., "Price")
          const displayName =
            filterType.charAt(0).toUpperCase() + filterType.slice(1);

          // Return a span or any other element for displaying the filter and its values
          return (
            <span key={filterType} style={{ marginRight: '10px' }}>
              {displayName}: {valueString}
            </span>
          );
        })}
      </div>
    );
  }
  const handleInnerSearch = (e) => {
    if (e) e.preventDefault(); // Prevent default form submission behavior
    // Construct the base URL with the inner search term and common parameters
    let baseUrl = `/search?query=${encodeURIComponent(
      innerSearchTerm
    )}&order=${order}&page=1`;

    // Construct a query string from the selected filters
    const filterQueryString = Object.entries(selectedFilters)
      .reduce((acc, [key, values]) => {
        if (values.length > 0) {
          acc.push(`${key}=${values.map(encodeURIComponent).join(',')}`);
        }
        return acc;
      }, [])
      .join('&');

    // Append the filters and the newSearch parameter to the base URL
    const finalUrl = `${baseUrl}${
      filterQueryString ? '&' + filterQueryString : ''
    }&newSearch=no`;

    // Navigate to the updated URL
    navigate(finalUrl);
  };

  const handlePageChange = (e) => {
    const selectedPage = e.target.value;
    navigate(getFilterUrl({ page: selectedPage }));
  };

  const RenderFilters = ({
    filterConfigs,
    filterRenderItems,
    selectedGroupFilters,
    setSelectedGroupFilters,
  }) => {
    const groupedConfigs = filterConfigs.reduce((acc, config) => {
      if (config.groupName) {
        if (!acc[config.groupName]) {
          acc[config.groupName] = [];
        }
        acc[config.groupName].push(config);
      } else {
        acc[config.key] = [config];
      }
      return acc;
    }, {});

    const handleGroupFilterChange = (groupName, selectedKey) => {
      setSelectedGroupFilters((prev) => ({
        ...prev,
        [groupName]: selectedKey,
      }));

      // Clear all selected filters corresponding to the groupKey
      const groupFilters = groupedConfigs[groupName];
      if (groupFilters) {
        const filterKeysToClear = groupFilters.map((config) => config.key);
        setSelectedFilters((prevFilters) => {
          const newFilters = { ...prevFilters };
          filterKeysToClear.forEach((key) => {
            delete newFilters[key];
          });
          return newFilters;
        });
      }
    };

    return (
      <div>
        {Object.keys(groupedConfigs).map((groupKey) => {
          const configs = groupedConfigs[groupKey];
          const isGroup = configs.length > 1;
          let selectedConfigKey = selectedGroupFilters[groupKey];

          if (isGroup && !selectedConfigKey) {
            selectedConfigKey = configs[0].key;
            setSelectedGroupFilters((prev) => ({
              ...prev,
              [groupKey]: selectedConfigKey,
            }));
          }

          return (
            <div key={groupKey}>
              {isGroup ? (
                <div>
                  <h3>{groupKey}</h3>
                  <select
                    value={selectedConfigKey}
                    onChange={(e) =>
                      handleGroupFilterChange(groupKey, e.target.value)
                    }
                  >
                    {configs.map((config) => (
                      <option key={config.key} value={config.key}>
                        {config.displayName}
                      </option>
                    ))}
                  </select>
                  {selectedConfigKey && (
                    <RenderFilter
                      filterConfig={configs.find(
                        (config) => config.key === selectedConfigKey
                      )}
                      filterData={filterRenderItems[selectedConfigKey] || []}
                      groupedFilters={groupedConfigs} // Pass groupedConfigs as groupedFilters
                    />
                  )}
                </div>
              ) : (
                <RenderFilter
                  filterConfig={configs[0]}
                  filterData={filterRenderItems[configs[0].key] || []}
                  groupedFilters={groupedConfigs} // Pass groupedConfigs as groupedFilters
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  if (filtersLoading || Object.keys(filterRenderItems).length === 0) {
    return <LoadingBox />;
  }

  return (
    <div className="search-screen">
      <Helmet>
        <title>FindFast.ai</title>
      </Helmet>
      <Row>
        <Col md={3}>
          {/* Search within results UI */}
          <div className="mb-3">
            <Form
              className="d-flex me-auto"
              onSubmit={(e) => handleInnerSearch(e)}
            >
              <InputGroup>
                <FormControl
                  type="text"
                  name="query"
                  id="query"
                  value={innerSearchTerm}
                  onChange={(e) => setInnerSearchTerm(e.target.value)}
                  placeholder="Search within results..."
                  aria-label="Search within results..."
                  aria-describedby="button-search"
                ></FormControl>
                <Button variant="info" type="submit" id="button-search">
                  <i className="fas fa-search"></i>
                </Button>
              </InputGroup>
            </Form>
          </div>
          <RenderFilters
            filterConfigs={filterConfigs}
            filterRenderItems={filterRenderItems}
            selectedGroupFilters={selectedGroupFilters} // Pass as prop
            setSelectedGroupFilters={setSelectedGroupFilters} // Pass as prop
          />
        </Col>
        <Col md={9}>
          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <>
              <Row className="justify-content-between mb-3">
                <Col md={6}>
                  <div>
                    {countProducts === 0 ? 'No' : countProducts} Results
                    {Object.keys(selectedFilters).length > 0 ||
                    innerSearchTerm ||
                    (query && query != 'all') ? (
                      <Button
                        variant="light"
                        onClick={() => {
                          setSelectedFilters({});
                          setInnerSearchTerm('');
                          navigate(
                            '/search?query=all&order=' + order + '&page=1'
                          );
                        }} // Assuming setSelectedFilters is your state updater function
                      >
                        Clear all filters
                      </Button>
                    ) : null}
                    {/* <DisplaySelectedFilters selectedFilters={selectedFilters} /> */}
                  </div>
                </Col>
                <Col className="text-end">
                  Sort by{' '}
                  <select
                    value={order}
                    onChange={(e) => {
                      handleSortChange(e.target.value);
                    }}
                    className="custom-select-box"
                  >
                    {sortParameters.map((param) => {
                      const className = param.label.includes('FindFast')
                        ? 'find-fast-option'
                        : '';
                      return (
                        <option
                          key={param.value}
                          value={param.value}
                          className={className}
                        >
                          {param.label}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              {products.length === 0 && (
                <MessageBox>No Product Found</MessageBox>
              )}

              <Row>
                {products.map((product) => (
                  <Col xs={6} sm={6} lg={4} className="mb-3" key={product._id}>
                    <Product
                      product={product}
                      selectedFilters={selectedFilters}
                    ></Product>
                  </Col>
                ))}
              </Row>
              <div className="pagination">
                <select
                  className="form-select"
                  value={page}
                  onChange={handlePageChange}
                  style={{ width: 'auto', display: 'inline-block' }}
                >
                  {[...Array(pages).keys()].map((x) => (
                    <option key={x + 1} value={x + 1}>
                      Page {x + 1}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
