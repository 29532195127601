import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import axios from 'axios';
import { useContext } from 'react';
import './Product.css';

function Product(props) {
  const { product, selectedFilters } = props;

  const associateID = 'findfast0a-20'; // Replace with your actual Amazon Associates ID

  const constructAmazonLink = (asin) => {
    return `http://www.amazon.com/dp/${asin}/ref=nosim?tag=${associateID}`;
  };

  const findVariantLink = () => {
    if (!selectedFilters || Object.keys(selectedFilters).length === 0) {
      return constructAmazonLink(product.asin);
    }

    const variantFilters = Object.entries(selectedFilters).filter(([key, _]) =>
      key.startsWith('variants.')
    );
    if (variantFilters.length === 0) {
      return constructAmazonLink(product.asin);
    }

    for (const variant of product.variants) {
      let matchesAll = variantFilters.every(([filterKey, filterValues]) => {
        const property = filterKey.split('.')[1]; // splits "variants.color" into ["variants", "color"]
        return filterValues.includes(variant[property]);
      });
      if (matchesAll) {
        return constructAmazonLink(variant.asin);
      }
    }

    return constructAmazonLink(product.asin); // Fallback if no variants match
  };

  const variantLink = findVariantLink();

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || 1; // Default to page 1 if no page is specified
    let queryParams = new URLSearchParams({
      ...filter,
      page: filterPage, // Ensure page is included in the query parameters
      order: 'bestseller',
    });

    return `/search?${queryParams.toString()}`;
  };

  return (
    <Card className="custom-card">
      <a href={variantLink} target="_blank" rel="noopener noreferrer">
        <img
          src={product.main_image.link}
          className="card-img-top"
          alt={product.name}
          style={{
            height: '300px',
            width: '100%',
            objectFit: 'cover',
            objectFit: 'scale-down',
          }}
        />
      </a>
      <Card.Body>
        <a href={variantLink} target="_blank" rel="noopener noreferrer">
          <Card.Title
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
              height: '96px',
            }}
          >
            {product.title}
          </Card.Title>
        </a>
        <Rating rating={product.rating} numReviews={product.ratings_total} />
        {product.buybox_winner.price ? (
          <Card.Text>${product.buybox_winner.price.value}</Card.Text>
        ) : (
          <Card.Text style={{ visibility: 'hidden' }}>$0.00</Card.Text>
        )}

        {product.bestsellers_rank &&
          Array.isArray(product.bestsellers_rank) && (
            <ul className="no-bullet-list">
              {(() => {
                const lowestRank = product.bestsellers_rank.reduce(
                  (min, rankInfo) =>
                    rankInfo.rank < min.rank ? rankInfo : min,
                  product.bestsellers_rank[0]
                );
                return (
                  <li key={lowestRank.category}>
                    Sales Rank #{lowestRank.rank} in{' '}
                    <a
                      href={getFilterUrl({
                        categories_flat: lowestRank.categories_flat,
                      })}
                    >
                      {lowestRank.category}
                    </a>
                  </li>
                );
              })()}
            </ul>
          )}

        {/* */}
      </Card.Body>
    </Card>
  );
}
export default Product;
